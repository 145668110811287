import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpeg'
import IMG4 from '../../assets/portfolio3.jpg'
import IMG5 from '../../assets/suikatchat.JPG'

const data = [
  {
    id: 1,
    image: IMG5,
    title: 'Application de Tchat',
    techno: 'Java - Spring Boot - Angular',
    github: 'https://github.com/certification-M2i',
  },
  {
    id: 2,
    image: IMG2,
    title: 'Site de location de voitures',
    techno: 'Bootstrap - PHP - MySQL',
    github: 'https://github.com/AuroreDsmith/veville',
    demo: 'https://veville-location.000webhostapp.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'PWA - Progressive Weather App',
    techno: 'REACT',
    github: 'https://github.com/AuroreDsmith/',
    demo: 'https://rainbow-toffee-2b574c.netlify.app'
  },
  {
    id: 4,
    image: IMG1,
    title: 'Boutique en ligne de vêtements',
    techno: 'HTML - CSS - Javascript',
    github: 'https://github.com/AuroreDsmith/PetitCommerce',
    demo: 'https://le-petit-commerce.netlify.app/'
  },
  {
    id: 5,
    image: IMG4,
    title: 'Autres projets à venir',
    techno: '',
    github: 'https://github.com/AuroreDsmith/pwa-weather-app',
    demo: 'https://auroredesmis.eu'
  }
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Mes derniers travaux</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, techno, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />     
                </div>
                <h3>{title}</h3>
                <small>{techno}</small>
                <div className="portfolio__item-cta">
                  <a href={github} className="btn" target="_blank" rel="noreferrer">Github</a>
                  <a href={demo} className="btn btn-primary" target="_blank" rel="noreferrer">Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio