import React from 'react'
import './footer.css'
import {GrLinkedin} from 'react-icons/gr'
import {FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo"><svg width="330.49641693811077" height="195.20719562859472" viewBox="0 0 330.49641693811077 195.20719562859472" class="css-1j8o68f"><defs id="SvgjsDefs6540"><linearGradient id="SvgjsLinearGradient6543"><stop id="SvgjsStop6544" stop-color="#26275f" offset="0"></stop><stop id="SvgjsStop6545" stop-color="#813e96" offset="0.14"></stop><stop id="SvgjsStop6546" stop-color="#2e64af" offset="0.29"></stop><stop id="SvgjsStop6547" stop-color="#3faa49" offset="0.5"></stop><stop id="SvgjsStop6548" stop-color="#f2e64a" offset="0.71"></stop><stop id="SvgjsStop6549" stop-color="#f2903b" offset="0.86"></stop><stop id="SvgjsStop6550" stop-color="#ed4533" offset="1"></stop></linearGradient></defs><g id="SvgjsG6541" featurekey="tpnldm-0" transform="matrix(11.132560237863938,0,0,11.132560237863938,-1.253884918468045,-25.662801189319694)" fill="url(#SvgjsLinearGradient6543)"><path d="M14.78 18.86 q0.14 0.32 -0.07 0.65 t-0.57 0.33 l-13.32 0 q-0.18 0 -0.34 -0.09 t-0.24 -0.23 q-0.22 -0.32 -0.06 -0.66 l0.62 -1.46 q0.08 -0.2 0.26 -0.32 t0.38 -0.12 l9.32 0 l-3.28 -7.84 l-2.68 6.4 q-0.08 0.2 -0.25 0.31 t-0.39 0.11 l-1.68 0 q-0.38 0 -0.6 -0.32 q-0.08 -0.14 -0.1 -0.32 t0.04 -0.34 l4.06 -9.52 q0.08 -0.2 0.25 -0.32 t0.39 -0.12 l1.92 0 q0.22 0 0.39 0.12 t0.25 0.32 z M25.5 5.52 q1.22 0.46 2.2 1.48 q2.1 2.12 2.1 5.41 t-2.1 5.43 q-0.98 1.02 -2.2 1.48 q-1.26 0.52 -2.58 0.52 l-5.66 0 q-0.28 0 -0.49 -0.21 t-0.21 -0.49 l0 -1.44 q0 -0.3 0.21 -0.51 t0.49 -0.21 l5.6 0 q1.78 0 2.89 -1.3 t1.11 -3.26 t-1.11 -3.26 t-2.89 -1.3 l-5.6 0 q-0.28 0 -0.49 -0.21 t-0.21 -0.51 l0 -1.44 q0 -0.28 0.21 -0.49 t0.49 -0.21 l5.66 0 q1.32 0 2.58 0.52 z"></path></g></svg></a>
      


      <ul className="permalinks">
        <li><a href="#">Accueil</a></li>
        <li><a href="#about">A propos</a></li>
        <li><a href="#experience">Expériences</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Témoignages</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/aurore-desmis/"><GrLinkedin/></a>
        
      </div>

      <div className="footer__copyright">
        <small>&copy; Aurore Desmis - {new Date().getFullYear()}</small>
      </div>
    </footer>
  )
}

export default Footer