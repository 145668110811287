import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/raphaellelecot.jpg'
import AVTR2 from '../../assets/francismunabeno.jpg'
import AVTR3 from '../../assets/remiviguie.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    id: 1,
    image: AVTR1,
    name: 'Raphaëlle Lecot, Webdesigner, Développeuse Web freelance et Formatrice Frontend',
    message: 'Excellent travail, tu peux être fière de toi ! Ton site est complet et bien codé, le design de qualité professionnelle. Tous les aspects (code, responsive design, référencement) ont été travaillés en détail, je te félicite c\'est parfait !' 
  },
  {
    id: 2,
    image: AVTR2,
    name: 'Francis Munabeno, Développeur et Formateur PHP',
    message: 'Excellent projet ! Aurore est appliquée et motivée, le développement web n’aura bientôt plus de secret pour elle.' 
  },
  {
    id: 3,
    image: AVTR3,
    name: 'Rémi Viguié, Chef de projet Multimédia chez Pixine',
    message: 'Aurore est très impliquée au quotidien, sa rigueur et son organisation sont de réels atouts pour le poste qu’elle occupe. Très motivée et curieuse nous sommes convaincus qu’elle arrivera à relever les défis qui lui permettront de devenir une très bonne développeuse. Elle doit encore se perfectionner techniquement (ce qui est normal) et continuer à travailler de son côté, à chercher des solutions par elle-même pour enrichir ses compétences.'
  }
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Ce qu'on pense de moi</h5>
      <h2>Témoignages</h2>

      <Swiper className="container testimonials__container" 
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({id, image, name, message}) => {
            return (
              <SwiperSlide key={id} className="testimonial">
                <div className="client__avatar">
                  <img src={image} alt={name} />
                </div>
                <h5 className="client__name">{name}</h5>
                <small className="client__review">
                  {message}
                  </small>   
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials