import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

function Experience() {
  return (
    <section id="experience">
      <h5>Mes compétences</h5>
      <h2>Mon Expérience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Développement Frontend</h3>
            <div className="experience__content">
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>HTML</h4>
                  <small className='text-light'>Avancé</small>
                </div>
              </article>
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>CSS</h4>
                  <small className='text-light'>Avancé</small>
                </div>
              </article>
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>Javascript</h4>
                  <small className='text-light'>Intermédiaire</small>
                </div>
              </article>
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>React/Angular</h4>
                  <small className='text-light'>Bases</small>
                </div>
              </article>
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>Bootstrap</h4>
                  <small className='text-light'>Intermédiaire</small>
                </div>
              </article>
              <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>Tailwind</h4>
                  <small className='text-light'>Intermédiaire</small>
                </div>
              </article>
            </div>
        </div>

        <div className="experience__backend">
          <h3>Développement Backend</h3>
          <div className="experience__content">
            <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>Java</h4>
                  <small className='text-light'>Intermédiaire</small>
                </div>
            </article>
            <article className="experience__details">
                <BsPatchCheckFill className="experience__details-icon"/>
                <div>
                  <h4>Spring Boot</h4>
                  <small className='text-light'>Intermédiaire</small>
                </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>PHP/Laravel Blade</h4>
                <small className='text-light'>Bases</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>PostgreSQL</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>WordPress/Themosis</h4>
                <small className='text-light'>Notions</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
