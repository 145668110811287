import React from 'react'
import './contact.css'
import {MdOutlineMail} from 'react-icons/md'
import {CgPhone} from 'react-icons/cg'
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_riwoefm', 'template_9nbtnz7', form.current, 'hEXWxzCDsWbHYvVvm')
    
    e.target.reset()
  };

  return (
    <section id="contact">
      <h5>Envie d'en savoir plus?</h5>
      <h2>Contactez moi</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>aurore.desmis(at)gmail.com</h5>
            <a href="mailto:auroredesmis@gmail.com" target="_blank" rel="noreferrer">Envoyer un message</a>
          </article>

          <article className="contact__option">
            <CgPhone className="contact__option-icon"/>
            <h4>Téléphone</h4>
            <h5>06 21 70 06 08</h5>
            <a href="tel:+33621700608">Appeler</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Votre nom et prénom" required/>
          <input type="email" name="email" placeholder="Votre email" required/>
          <textarea name="message" rows="7" placeholder="Votre message" required></textarea>
          <button type="submit" className="btn btn-primary">Envoyer</button>
        </form>
      </div>
    </section>
  )
}

export default Contact