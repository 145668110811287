import React from 'react'
import './about.css'
import ME from '../../assets/profil2.jpg'
import {FaAward} from 'react-icons/fa'
import {FaLaptopCode} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

function About() {
  return (
    <section id="about">
      <h5>A propos</h5>
      <h2>De Moi</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="profil" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Expérience</h5>
              <small>1 an</small>
            </article>

            <article className="about__card">
              <FaLaptopCode className='about__icon'/>
              <h5>Apprentissage</h5>
              <small>Continu</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projets</h5>
              <small>5+ complétés</small>
            </article>
          </div>

          <p>
          Après avoir occupé le poste de Technicienne chimiste puis Cheffe de projet dans l'industrie pharmaceutique pendant 13 ans, j’ai décidé en 2021 de me lancer un nouveau défi pour devenir développeuse web. J’ai donc suivi une formation diplômante de développeur intégrateur web auprès de l'Ifocop et ai réalisé, en février 2022, un stage de 4 mois dans l'agence de communication digitale <a href='https://www.pixine.fr/'>Pixine.</a><br/><br/>
          En 2023, j'ai pris une décision décisive : plonger dans l'univers de Java en suivant une formation intense (POEC) qui m'a propulsée vers une expertise opérationnelle dans le développement d'applications web, notamment avec l'utilisation de Spring Boot.  <br/><br/>
          Rigoureuse, dynamique et toujours avec la volonté d'apprendre, j’aime relever les défis et mets un point d’honneur à livrer des applications performantes qui satisfont les besoins des clients.
          </p>

          <a href="#contact" className='btn btn-primary'>Discutons ensemble</a>

        </div>
      </div>
    </section>
  )
}

export default About