import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>Ce que j'ai à vous offrir</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Développement d'application</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Capacité à écrire un code clair, efficace et bien structuré</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Utilisation de Java pour interagir avec des bases de données, gérer les requêtes et assurer l'intégrité des données.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Application des bonnes pratiques de développement logiciel, notamment en matière de conception orientée objet, de tests et de documentation.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Gestion des opérations de maintenance, de débogage et de correction des problèmes dans les applications Java existantes.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Expérience avec des frameworks comme Spring, Spring Boot ou Hibernate pour faciliter le développement d'applications.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Développement Web</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Production de lignes de code dans le cadre de la programmation de la plate-forme web.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Gestion et adaptation des solutions techniques lors du développement des fonctionnalités du site web.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Respect des pratiques courantes pour optimiser le référencement SEO ou le design responsive.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Gestion de la maintenance du site web.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Correction des problèmes d’utilisation ou d’ergonomie signalés par le client.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Versionning GIT.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Veille technique.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Création de contenu - UX/UI Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Conception du cahier des charges</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Référencement naturel SEO</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Sensibilité au design</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services